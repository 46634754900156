import React from "react";
import { connect } from 'react-redux';
import ChatErrandList from '../components/v5/ChatErrandList';
import { ChatErrand, AgentVideoFrame } from '../components/v5/ChatErrand';
import { ChatInput } from '../components/v5/ChatInput';
import { AddAgent } from '../components/v5/AgentList'; // TODO rename to AddAgent.js
import { IPAddress } from '../components/v5/IPAddress';
import { ChatOptions } from '../components/v5/ChatToolbar';
import ErrandList from '../components/v5/ErrandList';
import {
	ChatAreaDropdown,
	ChatAgentDropdown,
} from '../reactcomponents/Dropdown';
import {
	getCurrentChatErrand,
	getCurrentChatTemplate,
	getExtQueueType,
	getFBTypingStatus
} from '../common/v5/helpers.js';
import {
	closeVideoFrames,
	hangUpCall
} from '../common/v5/webRtcCall.js';
import {
	buttonStateSelector,
	getClientAvatarByErrand
} from '../redux/selectors/errand';
import { internalCommentSelector, isScreenSharingMode, isCoBrowsingMode } from '../redux/selectors/chat'
import {
	chatSetSeenMessages,
	fetchChatAreaCandidates,
	forwardChatToArea,
	fetchChatAgentCandidates,
	forwardChatToAgent,
	doUploadChatTempFile,
	satisfactionChatAction,
	handleSocialChatEmoticon
} from '../redux/actions/async/echat';
import {
	createOrEditNote,
	editErrandNotes,
	closeErrandView,
	translation,
	detectLanguage,
	reverseTranslation,
	recordEditorSize,
	reloadBasicErrand,
	removeAgentWorkingOnErrand,
	doCloseErrand,
	setAgentAssistTogglePref
} from '../redux/actions/async/errand';
import { fetchKnowledgeBaseCategory, fetchKnowledgeBase } from '../redux/actions/async/knowledgebase';
import {
	errandPreview
} from '../redux/actions/async/workflow';
import {
	chatCheckCanClose,
} from '../redux/actions/async/chat';
import AgentSocket from '../common/v5/agentsocket';
import {
	evtCHAT_ANSWER_INVITE,
	ECHAT_ANSWER_INVITE,
	FETCH_CHAT_AGENT_CANDIDATES_FAILED,
	FETCH_CHAT_AREA_CANDIDATES_FAILED,
	FETCHED_CHAT_AGENT_CANDIDATES,
	FETCHED_CHAT_AREA_CANDIDATES,
	FETCHING_CHAT_AGENT_CANDIDATES,
	FETCHING_CHAT_AREA_CANDIDATES,
	FORWARD_CHAT_TO_AGENT_STARTED,
	FORWARD_CHAT_TO_AGENT_SUCCEEDED,
	FORWARD_CHAT_TO_AREA_STARTED,
	FORWARD_CHAT_TO_AREA_FAILED,
	FORWARD_CHAT_TO_AREA_SUCCEEDED,
	CHAT_ADD_KB_FILE,
	UPDATE_CHAT_TYPING_STATUS,
	CHAT_SET_SUMMARY
} from '../redux/constants/constants';
import {
	BTN_CLEAR,
	BTN_SAVE,
	BTN_SEND,
	CHAT_crOwner,
	RPLY_COMMENT,
	RPLY_ERRAND,
	RPLY_COLLABORATE,
	RPLY_GRP_CHAT_COL,
	MP_MINIMIZE,
	RPLY_CHAT_SUMMARY
} from '../common/v5/constants';
import {
	changeReply,
	loadCollaboration,
	submitCollaboration
} from '../redux/actions/async/collaborate';
import {
	togglePopAlert,
} from '../redux/actions/hmf';
import {
	enableConfirm
} from '../redux/actions/async/hmf';
import {
	changeErrandHistorySelections,
	clearInputText,
	inputTextChanges,
	expandHideOption,
	setAnswerTranslation,
	setChatTranslation,
	selectShowReply,
	uploadedChatAttachments,
	toggleExternalData,
	toggleChatRatingBox,
	handleChatRating,
	handleChatRatingBody,
	resetAgentSatisfaction,
	toggleClientChatRatingBox,
	toggleErrandOptionMobile,
	toggleRightPanel,
	toggleVisitorPath,
	toggleErrandChatSidePanel,
	selectChatReplyTab,
	toggleVidChatFullScreen,
	updateVideoCallTimer,
	showVideoCallFrame,
	handleClientRejectVidCall,
	toggleWaTemplPopup,
	setWaTemplContent,
	saveWATemplateId,
	setQuickReplyContent,
	handleAgentMouseAccess,
	handleAgentKeyboardAccess,
	showErrandRewriteAnswerPopup,
	toggleAttachmentList,
	toggleRightSidePanel,
	doExpandKnowledgeBasePanel,
	setKBTreeParent,
	resetErrandLibrarySearch
} from '../redux/actions/errand';
import { toggleWorkflowPopup } from '../redux/actions/workflow';
import { toggleSipOutgoingPopup } from '../redux/actions/async/sippRtc';
import {
	contextMemo
	, getPreviewFeatures
} from '../redux/selectors/workflow';
import { hasCollaborationMemo } from '../redux/selectors/collaborate';
import {
	agentTimezoneOffsetSelector,
	fileArchivesSelector,
	libraryShortcutsSelector,
	currentStateMemoize,
	replyAnswerSelector,
	replyNavOptionsSelector
} from '../redux/selectors/errand';
import { allowTextCommentChatRating } from '../redux/selectors/server';
import {
	canTranslateChatSelector,
	getChatTranslateToSelector,
	getChatTranslateFromSelector,
	getChatTranslateDetectSelector
} from '../redux/selectors/translation';
import {
	mobileStateSelector
} from '../redux/selectors/mobile';

export function translationHandler(dispatch, field, html, from, to) {
	if (field === "detect") {
		return dispatch(detectLanguage(html));
	} else if (field === "translate") {
		return dispatch(translation(html, from));
	} else if (field === "reverseTranslation") {
		return dispatch(reverseTranslation(html, from, to));
	}
}

const mapAgentTimezoneOffset = (state, props) => {
	const wf = state.app.workflow
		, d = state.domain
		;
	return {
		tzOffset: agentTimezoneOffsetSelector(state, props),
		previewData: d.previewData.byId[wf.ui.currentPreviewId] ? d.previewData.byId[wf.ui.currentPreviewId] : "",
		previewActivated: getPreviewFeatures(wf.fetchWfSettings.data ? wf.fetchWfSettings.data : {}, contextMemo(state))
	}
}

const mapDispatchToChatErrandListProps = (dispatch, props) => {
	return {
		setMessagesSeen: chat => {
			let ids = [];
			$.each(chat.messages, (i, m) => {
				if (m.seen || m.aid == initialChatData.agentID) {
					return;
				}
				ids.push(m.id);
			});
			if (ids.length == 0) {
				return;
			}
			chatSetSeenMessages(dispatch, chat, ids);
		},
		onLoadPreview: (id) => {
			if (id > 0) {
				dispatch(errandPreview(id));
			}
		}
	}
}
// We need this so that ChatErrandList has props.dispatch
export const ChatErrandListCtnr = connect(
	mapAgentTimezoneOffset
	, mapDispatchToChatErrandListProps
)(ChatErrandList);

export const ErrandListCtnr = connect(
	mapAgentTimezoneOffset,  
	mapDispatchToChatErrandListProps
)(ErrandList);


const mapStateToProps = (state) => {
	const show = state.app.errand.ui.showComment, wf = state.app.workflow, wfs = wf.fetchWfSettings.data;
	let lockEditErrandNote = wfs["notes.errand.edit"]
	, e = state.app.errand
	, ui =  e.ui;
	return {
		allowTextCommentChatRating: allowTextCommentChatRating(state),
		chat: getCurrentChatErrand(state),
		hasCollaboration: hasCollaborationMemo(state),
		agentList: state.app.agentStore,
		clientAvatar: getClientAvatarByErrand(state),
		show: show,
		canTranslate: canTranslateChatSelector(state),
		interfaceLang: wf.fetchWfSettings.data.interface,
		toLang: getChatTranslateToSelector(state),
		fromCust: getChatTranslateDetectSelector(state),
		fromLang: getChatTranslateFromSelector(state),
		currentReply: state.app.errand.ui.reply.current,
		showReply: state.app.errand.ui.reply.show,
		currentState: currentStateMemoize(state),
		internatChatWindow: state.app.footer.uiData.activateChatWindow,
		showHeader: wf.fetchWfSettings.data.showErrandHeader,
		showExternalData: state.app.errand.ui.showExternalData,
		chatSatisfaction: state.app.errand.ui.chatSatisfaction,
		clientChatSatisfaction: state.app.errand.ui.clientChatSatisfaction,
		lockEditErrandNote: lockEditErrandNote,
		currentEditNote: state.app.errand.inputs.current_edit_note.note,
		mobileView: wf.ui.showMobileView,
		mobile: mobileStateSelector(state),
		showRightPanel: state.app.errand.ui.rightPanelOpen,
		showVisitorPath: ui.showVisitorPath,
		showChatSidePanel: ui.chatSidePanelOpen,
		showAIAnswerPanel: ui.showAIAnswerPanel,
		showKBLibraryPanel: ui.showKnowledgeBasePanel,
		expandKnowledgeBasePanel: ui.expandKnowledgeBasePanel,
		libraryAnswerShown: ui.knowledgeBase.shownId ? true : false,
		pinnedShowAIAnswerPanel: wfs.showAgentAssistPanel,
		showCollaborationPanel: ui.collaboration.show,
		showCommentPanel: ui.showComment,
	};
};

const mapDispatchToChatProps = (dispatch, props) =>{
	return{
		onToggleShow: (eid, toggleState) => {
			dispatch(expandHideOption('note', eid, toggleState));
		},
		onDeleteNoteClick: (note, errand) => {
			const text = I('Are you sure delete note {NOTE_ID}?')
				.replace('{NOTE_ID}', note);
			let isChat=true;
			dispatch(enableConfirm('delete_note', text, {note, errand,
				isChat}))
			.then((rs)=> {
				return dispatch(reloadBasicErrand(errand));
			})
			.catch(error => {
				console.log('error saving note', error);
			});
		},
		onEditNoteClick: (noteId, errandId) => {
			dispatch(editErrandNotes(noteId, errandId, true));
		},
		getTranslation: (field, html, from, to) => {
			if(field === "detect") {
				return dispatch(detectLanguage(html));
			}
			if(field === "translate") {
				return dispatch(translation(html, from, to));
			}
			if(field === "reverseTranslation") {
				return dispatch(reverseTranslation(html, from, to));
			}
		},
		onToggleReply: () => {
			dispatch(selectShowReply());
		},
		onToggleExternalData: () => {
			dispatch(toggleExternalData());
		},
		onToggleChatRatingBox: (t) =>{
			dispatch(toggleChatRatingBox(t));
		},
		onHandleChatLike: (r) =>{
			dispatch(handleChatRating(r, 'like'));
		},
		onHandleChatUnlike: (r) =>{
			dispatch(handleChatRating(r, 'unlike'));
		},
		handleChatRatingBody: (b) =>{
			dispatch(handleChatRatingBody(b));
		},
		onResetAgentSatisfaction: () => {
			dispatch(resetAgentSatisfaction());
		},
		onSendChatRating: (sId, r, t) =>{
			dispatch(satisfactionChatAction(sId, r, t));
		},
		onToggleClientChatRatingBox: (t) => {
			dispatch(toggleClientChatRatingBox(t))
		},
		onHandleChatEmoticon: (action, mId, session, channel) =>{
			dispatch(handleSocialChatEmoticon(action, mId, session, channel));
		},
		onToggleErrandOptionMobile: toggleState => {
			dispatch(toggleErrandOptionMobile(toggleState));
		},
		onToggleRightPanel: toggle => {
			dispatch(toggleRightPanel(toggle));
		},
		onToggleErrandChatSidePanel: toggle => {
			dispatch(toggleErrandChatSidePanel(toggle));
		},
		onToggleVisitorPath: (toggleState) => {
			dispatch(toggleVisitorPath(toggleState));
		},
		onMakeSIPCall: (contact) => {
			dispatch(toggleWorkflowPopup(MP_MINIMIZE));
			dispatch(toggleSipOutgoingPopup(true, true, contact));
		},
		onExpandAIAnswer: (toggle) => {
			//not used
		},
		onMaximizePanel: (toggle) => {
			dispatch(showErrandRewriteAnswerPopup(true, RPLY_ERRAND));
		},
		onCloseAIAnswerPanel: (eid) => {
			dispatch(expandHideOption('AIAnswerPanel', eid, false));
			dispatch(toggleRightSidePanel(false));
		},
		onBackToMainKBList: (library, category) => {
			if(category && category !== 0) {
				dispatch(fetchKnowledgeBaseCategory(category));
				dispatch(setKBTreeParent(""));
			} else {
				dispatch(fetchKnowledgeBase(library));
				if(category === 0) {
					dispatch(setKBTreeParent(""));
				} else {
					dispatch(setKBTreeParent(0)); //to indicate that we are at the root level
				}
			}
			dispatch(resetErrandLibrarySearch());
		},
		onExpandKBLibrary: (toggle) => {
			dispatch(doExpandKnowledgeBasePanel(toggle));
		},
		onCloseKBLibraryPanel: () => {
			dispatch(expandHideOption('KnowledgeBasePanel', 0, false));
			dispatch(toggleRightSidePanel(false));
		},
		onClickAgentAssistTrigger: (toggle) => {
			dispatch(expandHideOption('AIAnswerPanel', 0, toggle));
		},
		onClickPinAgentAssist: (toggle) => {
			dispatch(setAgentAssistTogglePref(toggle));
		},
		onShowCollaborationPanel: (toggle) => {
			dispatch(expandHideOption('collaboration', 0, toggle));
		},
		onShowCommentPanel: (toggle) => {
			dispatch(expandHideOption('note', 0, toggle));
		}
	};
};

const mapStateToChatInputProps = (state, props) => {
	let wf = state.app.workflow
	, e = state.app.errand
	, chat = state.chat
	, inpts = e.inputs
	, wfs = wf.fetchWfSettings;
	let chatBoxHeight = state.server.features["workflow.chatbox-height"];
	if(e.recordEditorSize && e.recordEditorSize.data){
		if(typeof e.recordEditorSize.data.chat !== 'undefined'){
			chatBoxHeight = e.recordEditorSize.data.chat;
		}
	}
	return {
		buttonCondition: buttonStateSelector(state, props),
		internalComment: internalCommentSelector(state, props),
		chat: getCurrentChatErrand(state),
		ckeditorContent: replyAnswerSelector(state, props),
		errandListChat: wf.errandListChat,
		ckEditorBox: wfs.data.answerWysiwyg,
		archiveImgs: fileArchivesSelector(state, props),
		internatChatWindow: state.app.footer.uiData.activateChatWindow,
		canTranslate: canTranslateChatSelector(state),
		fromCust: getChatTranslateDetectSelector(state),
		toLang: getChatTranslateToSelector(state),
		fromLang: getChatTranslateFromSelector(state),
		shortcutKeys: libraryShortcutsSelector(state, props),
		preferredChatBoxHeight: chatBoxHeight,
		currentChatErrandId: state.app.errand.currentChatErrandId,
		fbKeyTypingStatus: getFBTypingStatus(state),
		waTemplCode: inpts.templCode,
		template: getCurrentChatTemplate(state),
	};
};

const mapDispatchToChatInputProps = (dispatch, props) => {
	return {
		dispatch,
		showError: msg => {
			dispatch(togglePopAlert(msg));
		},
		// TODO: refactor this and onButtonClick as they share the same pattern as
		// errand.
		onAnswerChange: (value, plain, reformat) => {
			if (props.currentReply === RPLY_COLLABORATE) {
				dispatch(inputTextChanges(
					'update_answer',
					value,
					plain,
					RPLY_COLLABORATE
				));
			} else if (props.currentReply === RPLY_COMMENT) {
				dispatch(inputTextChanges('internal_comment', value, plain));
			}
		},
		onButtonClick: (type, chat) => {
			if (type === BTN_CLEAR) {
				switch(props.currentReply) {
					case RPLY_COLLABORATE:
						dispatch(clearInputText('update_answer', RPLY_COLLABORATE));
						break;
					case RPLY_COMMENT:
						dispatch(clearInputText('internal_comment'));
						break;
				}
			} else if (type === BTN_SEND) {
				switch(props.currentReply) {
					case RPLY_COLLABORATE:
						dispatch(submitCollaboration(chat));
						break;
					case RPLY_COMMENT:
						dispatch(createOrEditNote(false));
						break;
				}
			} else if (type === BTN_SAVE) {
				if (props.currentReply === RPLY_COMMENT) {
					dispatch(createOrEditNote(false));
				}
			} else {
				if (process.env.NODE_ENV !== 'production') {
					console.log('dbg: unknown button type', type);
				}
			}
		},
		onInternalCommentChange: msg => {
			dispatch(inputTextChanges("internal_comment", msg));
		},
		onAddErrandNotes: (msg, plain) => {
			dispatch(inputTextChanges("internal_comment", msg, plain));
			dispatch(createOrEditNote(true));
		},
		answerInvite: (sessionId, accept) => {
			AgentSocket.SendEvent(evtCHAT_ANSWER_INVITE, {sessionId, accept}, response => {
				if (response.error) {
					let msg = I("Invitation error: {ERROR}").replace('{ERROR}', response.error);
					dispatch(togglePopAlert(msg));
					return;
				}
				dispatch({
					type: ECHAT_ANSWER_INVITE,
					chat: {
						sessionId: response.sessionId,
						isStale: response.isStale,
					},
				});
			});
		},
		onTranslation: (...args) => translationHandler(dispatch, ...args),
		onUploadChatAttachments: (chat, attachments) => {
			dispatch({type:CHAT_ADD_KB_FILE,chat,attachments});
		},
		onDragDropFiles: (fd, file, data) => {
			if(data){
				return dispatch(doUploadChatTempFile(data));
			}
		},
		onDropFiles: (chat,files) => {
			dispatch(selectShowReply(RPLY_ERRAND,true));
			return dispatch(uploadedChatAttachments(chat,files));
		},
		onRecordEditorSizes: (height) => {
			dispatch(recordEditorSize(height));
		},
		onHandleTypingStatus: (v, currentChatId) =>{
			dispatch({
				type: UPDATE_CHAT_TYPING_STATUS
				, status: v
				, currentChatId: currentChatId
			});
		},
		onTemplateIdReset: (tid, currentErrandId) => {
			dispatch(saveWATemplateId(tid, currentErrandId));
		},
		onToCloseWithSummary: () => {
			dispatch(selectShowReply(RPLY_CHAT_SUMMARY, true));
		},
		onChangeSummary: (sessionId, summary) => {
			dispatch({
				type: CHAT_SET_SUMMARY,
				chat: {
					sessionId,
					summary,
				},
			});
		},
		onCloseWithSummary: (eid) => {
			dispatch(doCloseErrand(eid, false, false));
		},
		onToCloseAndCreateErrand: (eid) => {
			dispatch(doCloseErrand(eid, false, false));
		}
	};
}

export const ChatErrandCtnr = connect(mapStateToProps, mapDispatchToChatProps)(ChatErrand);
export const ChatInputCtnr = connect(mapStateToChatInputProps, mapDispatchToChatInputProps)(ChatInput);

const mapStateToAgentVideoFrameProps = (state, props) => {
	return {
		isAgentVideoCallShowing: (state.app.errand.chat ? state.app.errand.chat.videoCall : false),
		videoCallInProgress: state.app.errand.chat.videoCallInProgress,
		videoCallFullScreen: state.app.errand.chat.videoCallFullScreen,
		videoCallClientMute: state.app.errand.chat.videoCallClientMuted,
		videoCallClientOffWebCam: state.app.errand.chat.videoCallClientOffWebCam,
		allowAgentVideoCall: state.server.features["chat.agent-video-call"],
		allowVideoRecording: state.server.features["chat.agent-video-call-record"],
		allowAutoVideoRecording: state.server.features["chat.agent-video-call-auto-record"],
		clientRejectVidCall: state.chat.socket.videoCallRejected,
		isClientCalling: state.chat.socket.clientVidCalling,
		recording: state.app.errand.chat.videoIsRecording,
		allowScreenSharing: state.server.features["chat.agent-video-screen-sharing"],
		allowCoBrowsing: state.server.features["chat.agent-video-co-browse"],
		screenShareMode: isScreenSharingMode(state, props),
		isClientSharingScreen: state.chat.socket.clientSharingScreen,
		isAgentSharingScreen: state.chat.socket.agentSharingScreen,
		showCoBrowseFrame: isCoBrowsingMode(state, props),
		clientSelectedDisplay: state.chat.socket.clientSelectedDisplay,
		agentMouseControl: state.chat.socket.agentMouseControl,
		agentKeyboardControl: state.chat.socket.agentKeyboardControl,
		videoExtractionDone: state.app.errand.chat.videoExtractionDone
	}
}

const mapDispatchToAgentVideoFrameProps  = (dispatch, props) => {
	return {
		onHangUpCall: (sid, bySystem) => {
			hangUpCall(sid, false, bySystem);
		},
		onVideoFullScreen: (toggle) => {
			dispatch(toggleVidChatFullScreen(toggle));
		},
		onUpdateVidChatTimer: (timer) => {
			dispatch(updateVideoCallTimer(timer));
		},
		onCloseVideoFrame: (sid) => {
			closeVideoFrames(sid);
		},
		onAgentCall: () => {
			dispatch(handleClientRejectVidCall(false));
		},
		onWindowBeforeUnload: (callInProgress, coBrowsingInProgress, e) => {
			if (!callInProgress && !coBrowsingInProgress) {
				return;
			}
			// NOTE: custom message no longer work in many of the modern
			// browsers. Refer to: https://stackoverflow.com/a/7317311/1778714
			const msg = I("The call is still in progress, do you want to proceed?");
			e = e || window.event;
			if (e) {
				e.returnValue = msg;
			}
			return msg;
		},
		onToggleMouseControl: (toggle) => {
			dispatch(handleAgentMouseAccess(toggle));
		},
		onToggleKeyboardControl: (toggle) => {
			dispatch(handleAgentKeyboardAccess(toggle));
		}
	}
}

export const AgentVideoFrameCtnr = connect(mapStateToAgentVideoFrameProps, mapDispatchToAgentVideoFrameProps)(AgentVideoFrame);

const mapStateToChatOptionsProps = (state, props) => {
	let e = state.app.errand
		, wf = state.app.workflow
		, reply = e.ui.reply
		, einpt = e.inputs
		;
	return {
		chat: getCurrentChatErrand(state),
		interfaceLang: wf.fetchWfSettings.data.interface,
		toLang: getChatTranslateToSelector(state),
		fromLang: getChatTranslateFromSelector(state),
		canTranslate: canTranslateChatSelector(state),
		currentState: currentStateMemoize(state),
		options: replyNavOptionsSelector(state, props),
		selectedChatReplyTab: reply.selectedChatReplyTab,
		showWATemplatePopup: reply.showWATemplatePopup,
		showAttachmentPanel: e.ui.showAttachment,
		templContent: einpt.templContent,
		shouldShowOpenAI: wf.fetchWfSettings.data["openai-rewrite-answer"],
		shouldShowChatTranslation: wf.fetchWfSettings.data["chat.translation"],
	};
};

const mapDispatchToChatOptionsProps = (dispatch, props) => {
	return {
		dispatch,
		onSelectedToLang: (id, sessionObj) => {
			dispatch(setChatTranslation(id, sessionObj));
		},
		onSelectedFromLang: (id, sessionObj) => {
			dispatch(setChatTranslation(id, sessionObj));
		},
		onUploadChatAttachments: (chat, attachments) => {
			dispatch({type:CHAT_ADD_KB_FILE,chat,attachments});
		},
		onSelectCollaboration: () => {
			dispatch(loadCollaboration(RPLY_GRP_CHAT_COL));
		},
		onDefaultReply: () => {
			dispatch(changeErrandHistorySelections());
		},
		onSelectChatReplyTab: (tab) => {
			dispatch(selectChatReplyTab(tab));
		},
		onShowTemplateCustom: (tgl, wc, id) =>{
			dispatch(toggleWaTemplPopup(tgl));
			dispatch(setWaTemplContent(wc, id));
		},
		onTemplateChange: (tid, currentErrandId) => {
			dispatch(saveWATemplateId(tid, currentErrandId));
		},
		onToggleAttachmentPanel: (toggle) => {
			dispatch(toggleAttachmentList(toggle));
		},
		onInsertQuickReply: (tid, code, currentErrandId) => {
			dispatch(setQuickReplyContent(tid, code));
			//quick reply also used templateId, as templateId handles for whatsapp
			//so it better to reuse.
			dispatch(saveWATemplateId(tid, currentErrandId));
		}
	};
}

export const ChatOptionsCtnr = connect(mapStateToChatOptionsProps, mapDispatchToChatOptionsProps)(ChatOptions);

const mapStateToAddAgentProps = state => {
	return {
		chat: state.app.errand.chat,
		uiData: state.app.header.uiData,
		getAgent: id => state.app.agentStore[id],
		agentStore: state.app.agentStore,
	}
}

const mapDispatchToAddAgentProps = (dispatch, props) => {
	return {
		onDone: () => dispatch(changeReply(RPLY_ERRAND)),
		showError: msg => {
			dispatch(togglePopAlert(msg));
		},
	}
}

export const AddAgentCtnr = connect(mapStateToAddAgentProps, mapDispatchToAddAgentProps)(AddAgent);

const reportForwardError = (dispatch, error) => {
	let msg;
	switch (error) {
	case "ERR_NIL_AGENT":
		msg = I("No agents available");
		break;
	case "ERR_INTEGRATION":
		msg = I("Unsupported by integration");
		break;
	default:
		msg = I("Forward error: {ERROR}").replace('{ERROR}',  response.error);
	}
	dispatch(togglePopAlert(msg));
}

const mapStateToChatDropdownProps = (state) => {
	let wf = state.app.workflow, wfs = wf.fetchWfSettings;
	return {
		chat: getCurrentChatErrand(state),
		progress: wf.chatForwardArea.progress,
		areas: wf.chatForwardArea.areas,
		ready: wf.chatForwardArea.ready,
		forwarding: wf.chatForwardArea.forwarding,
		 errandListChat: wf.errandListChat,
		listView: wfs.data.agentErrandListViewPref
	}
}
const mapDispatchToChatDropdownProps = (dispatch, props) => {
	return {
		getAreaCandidates: (exclude, sessionId) => {
			dispatch({type:FETCHING_CHAT_AREA_CANDIDATES});
			dispatch(fetchChatAreaCandidates(exclude, sessionId))
			.then(
				response => dispatch({type: FETCHED_CHAT_AREA_CANDIDATES, response }),
				failed => dispatch({type: FETCH_CHAT_AREA_CANDIDATES_FAILED}),
			);
		},
		forwardToArea: (sessionId, eid, areaId, areaName, errandListChat, listView) => {
			dispatch({type:FORWARD_CHAT_TO_AREA_STARTED});
			dispatch(forwardChatToArea(sessionId, areaId))
			.then(
				response => {
					if (response.error) {
						reportForwardError(dispatch, response.error);
						dispatch({type:FORWARD_CHAT_TO_AREA_FAILED});
					} else if (response.successful) {
						dispatch({
							type: FORWARD_CHAT_TO_AREA_SUCCEEDED,
							response,
							chat: { sessionId },
							areaId,
							areaName,
						});
						if (response.isStale) {
							dispatch(removeAgentWorkingOnErrand(eid.toString()));
						}
						if (externalqueue && (externalqueue.isExternal == true
							|| externalqueue.isPostMessage == true) &&
							externalqueue.isChat == true) {
							dispatch(closeErrandView());
							if (errandListChat && errandListChat.length <= 1) {
								dispatch(chatCheckCanClose(getExtQueueType()));
							}
						} else if (!errandViewOnly && response.isStale) {
							dispatch(closeErrandView());
						}
					} else {
						alert("ERR_FORWARD_NOT_HANDLED");
					}
				},
				failed => { dispatch(togglePopAlert(I("An error occurred while forwarding, please try again"))); },
			);
		},
	}
}
export const ChatAreaDropdownCtnr = connect(mapStateToChatDropdownProps, mapDispatchToChatDropdownProps)(ChatAreaDropdown);

const mapStateToChatAgentDropdownProps = (state) => {
	let wf = state.app.workflow, wfs = wf.fetchWfSettings;
	return {
		chat: getCurrentChatErrand(state),
		progress: wf.chatForwardAgent.progress,
		agents: wf.chatForwardAgent.agents,
		ready: wf.chatForwardAgent.ready,
		forwarding: wf.chatForwardAgent.forwarding,
		errandListChat: wf.errandListChat,
		listView: wfs.data.agentErrandListViewPref
	}
}
const mapDispatchToChatAgentDropdown = (dispatch, props) => {
	return {
		getAgentCandidates: (sessionId) => {
			dispatch({type:FETCHING_CHAT_AGENT_CANDIDATES});
			dispatch(fetchChatAgentCandidates(sessionId))
			.then(
				response => dispatch({type: FETCHED_CHAT_AGENT_CANDIDATES, response}),
				failed => dispatch({type: FETCH_CHAT_AGENT_CANDIDATES_FAILED}),
			);
		},
		forwardToAgent: (sessionId, agentId, errandListChat, listView, id) => {
			dispatch({type:FORWARD_CHAT_TO_AGENT_STARTED});
			dispatch(removeAgentWorkingOnErrand(id.toString()));
			dispatch(forwardChatToAgent(sessionId, agentId))
			.then(
				response => {
					if (response.error) {
						reportForwardError(dispatch, response.error);
					} else if (response.successful) {
						dispatch({
							type: FORWARD_CHAT_TO_AGENT_SUCCEEDED,
							response,
							sessionId,
						});
						if(typeof externalqueue !== "undefined" &&
							externalqueue.isExternal == true){
							if(externalqueue.isChat == true){
								if(typeof errandListChat !== 'undefined' &&
									errandListChat != null &&
									errandListChat.length <= 1){
									dispatch(chatCheckCanClose(getExtQueueType()));
								}
							}
						} else if (!errandViewOnly && response.isStale) {
							dispatch(closeErrandView());
						}
					} else {
						alert("ERR_FORWARD_AGENT_NOT_HANDLED");
					}
				},
				failed => { dispatch(togglePopAlert(I("An error occurred while forwarding, please try again"))); },
			);
		},
	}
}
export const ChatAgentDropdownCtnr = connect(mapStateToChatAgentDropdownProps, mapDispatchToChatAgentDropdown)(ChatAgentDropdown);

export const IPAddressCtnr = connect()(IPAddress);
